import { getField, updateField } from 'vuex-map-fields'
import api from '@/api/api'
import { Produto } from '@/models/Produto'
import Swal from 'sweetalert2'
import { produtoTransformer } from '@/utils/transformers'
import { v4 } from 'uuid'

export const SET_STATE = 'SET_STATE'
export const BOOTSTRAP_PROFILE = 'BOOTSTRAP_PROFILE'
export const LOAD_CATEGORIAS = 'LOAD_CATEGORIAS'
export const LOAD_UNIDADES = 'LOAD_UNIDADES'
export const LOAD_FORNECEDORES = 'LOAD_FORNECEDORES'
export const SUBMIT = 'SUBMIT'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    categorias: [],
    unidades: [],
    fornecedores: [],
    apresentacao: '',
    modo_uso: '',
    consumo: '',
    indicacao: '',
    restricoes: '',
    validade: '',
    loading: true,
    isEditing: false,
    img: '',
    imagensVec: [{ id: v4(), img: null }],
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
}

const actions = {
  async [BOOTSTRAP_PROFILE] ({ dispatch, commit, state, rootState }, { id }) {
    commit(RESET_STATE)
    commit('SET_STATE', { loading: true, isEditing: false })
    dispatch('form/BOOTSTRAP_FORM', { model: new Produto() }, { root: true })
    await dispatch(LOAD_CATEGORIAS)
    await dispatch(LOAD_UNIDADES)
    await dispatch(LOAD_FORNECEDORES)
    if (id) {
      commit(SET_STATE, { isEditing: true })
      await api.getEntidade('produto', id).then(response => {
        dispatch('form/BOOTSTRAP_FORM', { model: new Produto(produtoTransformer(response.data[0])) }, { root: true })
        const imagensVec = rootState.form.data.imagens.map(
          img => ({ id: v4(), img }),
        )
        imagensVec.push({ id: v4(), img: null })
        commit(SET_STATE, {
          apresentacao: rootState.form.data.apresentacao,
          modo_uso: rootState.form.data.modo_uso,
          consumo: rootState.form.data.consumo,
          indicacao: rootState.form.data.indicacao,
          restricoes: rootState.form.data.restricoes,
          validade: rootState.form.data.validade,
          img: rootState.form.data.imagens,
          imagensVec,
        })
      })
    }
    commit(SET_STATE, { loading: false })
  },
  async [LOAD_CATEGORIAS] ({ commit, state }) {
    await api.listEntidade('categoria').then(
      categorias => {
        commit(SET_STATE, { categorias })
      },
    )
  },
  async [LOAD_UNIDADES] ({ commit }) {
    await api.listEntidade('unidade').then(
      unidades => {
        commit(SET_STATE, { unidades })
      },
    )
  },
  async [LOAD_FORNECEDORES] ({ commit, state }) {
    await api.listEntidade('fornecedor')
      .then(
        fornecedores => {
          fornecedores = fornecedores.map(f => {
            return {
              text: f.pessoaFis ? f.nome : f.nome_fantasia || f.razao_social,
              value: f.id,
              codigo: f.pessoaFis ? `CPF ${f.cpf}` : `CNPJ ${f.cnpj}`,
            }
          })
          commit(SET_STATE, { fornecedores })
        },
      )
  },
  async [SUBMIT] ({ commit, dispatch, state, rootState }, { router, user }) {
    Swal.fire({
      title: 'Enviando dados!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        const form = {
          ...rootState.form.data,
          imagens: state.imagensVec.map(v => v.img),
        }
        var rot = {
          indicacao: state.indicacao,
          modo_uso: state.modo_uso,
          restricoes: state.restricoes,
          consumo: state.consumo,
          validade: state.validade,
          apresentacao: state.apresentacao,
        }
        form.rotulo = JSON.stringify(rot);

        (() => {
          return state.isEditing
            ? api.salvarEntidade(form, 'produto', form.id)
            : api.cadastrarEntidade(form, 'produto')
        })().then(
          data => {
            window.Toast.fire(state.isEditing
              ? 'Produto atualizado com sucesso!'
              : 'Produto cadastrado com sucesso!', '', 'success')
            dispatch('produtos/BOOTSTRAP', null, { root: true })
            router.push({
              path: `/${user.get().role}/cdc/produtos`,
            })
          },
          error => {
            window.Toast.fire(error.message, '', 'error')
          },
        )
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
