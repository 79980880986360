<template>
  <div class="content">
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <crud-cadastro
        :loading="loading"
      >
        <v-card
          class="px-6 py-4"
          outlined
          style="margin-top: 14px; border-radius: 16px;"
        >
          <v-row justify="center">
            <div
              v-for="(imgs, index) in imagensVec"
              :key="imgs.id"
            >
              <crud-image-uploader
                v-model="imagensVec[index].img"
                dialog-title="Foto do produto"
                no-data="img/prodImage.png"
                height="400"
                class="mx-2"
                @delete="deleteImg(index)"
                @upload="addEspacoImagem()"
              />
            </div>
          </v-row>
        </v-card>
        <v-card
          class="px-6 pt-4"
          outlined
          style="margin-top: 14px; border-radius: 16px"
        >
          <v-card-title
            class="pa-0"
            style="text-shadow: grey 0.1em 0.1em 0.2em;"
          >
            Dados do Produto
          </v-card-title>
          <v-row>
            <v-col
              cols="3"
              md="3"
            >
              <v-text-field
                v-model="codigo"
                :rules="[rules.required]"
                label="Código*"
                required
                validate-on-blur
                outlined
                rounded
                dense
              />
            </v-col>
            <v-col
              cols="9"
              md="9"
            >
              <v-text-field
                v-model="nome"
                :rules="[rules.required]"
                label="Nome*"
                required
                validate-on-blur
                outlined
                rounded
                dense
              />
            </v-col>
          </v-row>
        </v-card>
        <v-card
          class="px-6 pt-4"
          outlined
          style="margin-top: 14px; border-radius: 16px"
        >
          <v-card-title
            class="pa-0"
            style="text-shadow: grey 0.1em 0.1em 0.2em;"
          >
            Familia / Unidade
          </v-card-title>
          <v-row>
            <v-col
              cols="5"
              md="5"
            >
              <v-text-field
                v-model="marca"
                :rules="[rules.required]"
                label="Marca*"
                required
                validate-on-blur
                outlined
                rounded
                dense
              />
            </v-col>
            <v-col
              cols="7"
              md="7"
            >
              <v-autocomplete
                v-model="fornecedor_id"
                :rules="[rules.required]"
                :items="fornecedores"
                item-value="value"
                class="d-flex justify-space-between"
                label="Fornecedor"
                validate-on-blur
                outlined
                rounded
                dense
              >
                <template
                  slot="item"
                  slot-scope="data"
                >
                  <v-container
                    class="rounded-lg mx-n2"
                    fluid
                  >
                    <v-row
                      dense
                      justify="space-between"
                    >
                      <div>
                        {{ data.item.text }}
                      </div>
                      <div>
                        {{ data.item.codigo }}
                      </div>
                    </v-row>
                  </v-container>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="5"
              md="5"
            >
              <v-autocomplete
                v-model="categoria_id"
                :items="categorias"
                item-text="nome"
                item-value="id"
                :rules="[rules.required]"
                class="d-flex justify-space-between"
                label="Categoria*"
                outlined
                rounded
                dense
              >
                <template
                  slot="item"
                  slot-scope="data"
                >
                  <v-container
                    class="rounded-lg mx-n2"
                    fluid
                  >
                    <v-row
                      dense
                      justify="space-between"
                    >
                      <div>{{ data.item.nome }}</div>
                    </v-row>
                  </v-container>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="3"
              md="3"
            >
              <v-autocomplete
                v-model="unidade_id"
                :items="unidades"
                item-text="nome"
                item-value="id"
                :rules="[rules.required]"
                class="d-flex justify-space-between"
                label="Unidade*"
                outlined
                rounded
                dense
              >
                <template
                  slot="item"
                  slot-scope="data"
                >
                  <v-container
                    class="rounded-lg mx-n2"
                    fluid
                  >
                    <v-row
                      dense
                      justify="space-between"
                    >
                      <div>{{ data.item.nome }}</div>
                    </v-row>
                  </v-container>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="2"
              md="2"
            >
              <base-vuetify-money
                v-model="volume"
                :rules="[rules.required]"
                maxlength="9"
                label="Volume*"
                required
                validate-on-blur
                outlined
                rounded
                dense
              />
            </v-col>
            <v-col
              cols="2"
              md="2"
            >
              <v-text-field
                v-model="validade"
                label="Validade (em dias)"
                :rules="[rules.numeric]"
                validate-on-blur
                outlined
                rounded
                dense
              />
            </v-col>
            <v-col />
          </v-row>
        </v-card>
        <v-card
          class="px-6 pt-4"
          outlined
          style="margin-top: 14px; border-radius: 16px"
        >
          <v-card-title
            class="pa-0"
            style="text-shadow: grey 0.1em 0.1em 0.2em;"
          >
            Rótulo
          </v-card-title>
          <v-row>
            <v-col
              cols="12"
            >
              <v-textarea
                v-model="descricao"
                :rules="[rules.required]"
                label="Descrição*"
                validate-on-blur
                outlined
                rounded
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-textarea
                v-model="apresentacao"
                label="Apresentação do produto"
                validate-on-blur
                outlined
                rounded
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-textarea
                v-model="consumo"
                label="Consumo recomendado"
                validate-on-blur
                outlined
                rounded
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-textarea
                v-model="restricoes"
                label="Restrições"
                validate-on-blur
                outlined
                rounded
              />
            </v-col>
            <v-col
              cols="6"
              md="6"
            >
              <v-textarea
                v-model="indicacao"
                label="Indicação"
                validate-on-blur
                outlined
                rounded
              />
            </v-col>
            <v-col
              cols="6"
              md="6"
            >
              <v-textarea
                v-model="modo_uso"
                label="Modo de uso do produto"
                validate-on-blur
                outlined
                rounded
              />
            </v-col>
          </v-row>
        </v-card>
        <v-row
          class="pa-3"
          justify="center"
          justify-sm="end"
        >
          <v-btn
            class="text-none text-white mr-3"
            color="error"
            rounded
            @click="$router.push({ path: `/${$user.get().role}/cdc/produtos` })"
          >
            <v-icon
              dark
              left
            >
              mdi-minus-circle
            </v-icon>
            Cancelar
          </v-btn>
          <v-btn
            class="text-none text-white"
            color="success"
            :disabled="desabilitaBotao"
            rounded
            @click="submitForm"
          >
            <v-icon
              dark
              left
            >
              mdi-checkbox-marked-circle
            </v-icon>
            {{ isEditing ? 'Salvar' : 'Cadastrar' }}
          </v-btn>
        </v-row>
      </crud-cadastro>
    </v-form>
  </div>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  import ProdutoFormStore, { BOOTSTRAP_PROFILE, SUBMIT } from '@/store/modules/forms/produto'
  import { mapState, mapActions } from 'vuex'
  import { v4 } from 'uuid'
  export default {
    data () {
      return {
        money: {
          locale: 'pt-BR',
          precision: 3,
        },
        valid: false,
        numImagens: 1,
        rules,
        snackbar: { message: '', show: false },
      }
    },
    computed: {
      ...mapState('form/produto', ['unidades', 'fornecedores', 'categorias', 'loading', 'isEditing']),
      ...mapFields('form', [
        'data.nome',
        'data.codigo',
        'data.marca',
        'data.unidade_id',
        'data.fornecedor_id',
        'data.categoria_id',
        'data.descricao',
        'data.preco_venda_sugerido',
        'data.rotulo',
        'data.volume',
      ],
      ),
      ...mapFields('form/produto', [
        'apresentacao',
        'modo_uso',
        'consumo',
        'indicacao',
        'restricoes',
        'validade',
        'imagensVec',
      ],
      ),
      desabilitaBotao () {
        return this.loading || !this.valid
      },
    },
    created () {
      if (!this.$store.state.form.produto) { this.$store.registerModule(['form', 'produto'], ProdutoFormStore) }
      const id = this.$route.query.id
      this.BOOTSTRAP_PROFILE({ id })
    },
    methods: {
      ...mapActions('form/produto', [BOOTSTRAP_PROFILE, SUBMIT]),
      addEspacoImagem () {
        if (this.numImagens < 10) {
          this.imagensVec.push({ id: v4(), img: null })
        }
      },
      deleteImg (index) {
        this.$delete(this.imagensVec, index)
      },
      submitForm () {
        if (!this.$refs.form.validate()) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }
        this.SUBMIT({ user: this.$user, router: this.$router })
      },
    },
  }
</script>
