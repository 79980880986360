import { createRotulo } from './Rotulo'

export class Produto {
  constructor ({
    id,
    codigo,
    nome,
    marca,
    fornecedor_id,
    categoria_id,
    unidade_id,
    descricao,
    imagens,
    preco_venda_sugerido,
    consumo,
    modo_uso,
    validade,
    indicacao,
    restricoes,
    apresentacao,
    volume,
  } = {
    id: '',
    codigo: '',
    nome: '',
    marca: '',
    fornecedor_id: '',
    categoria_id: '',
    unidade_id: '',
    descricao: '',
    imagens: [],
    preco_venda_sugerido: '',
    consumo: '',
    modo_uso: '',
    validade: '',
    indicacao: '',
    restricoes: '',
    apresentacao: '',
    volume: '',
  }) {
    this.id = id
    this.codigo = codigo
    this.nome = nome
    this.marca = marca
    this.fornecedor_id = fornecedor_id
    this.categoria_id = categoria_id
    this.unidade_id = unidade_id
    this.descricao = descricao
    this.imagens = imagens
    this.preco_venda_sugerido = preco_venda_sugerido
    this.consumo = consumo
    this.modo_uso = modo_uso
    this.validade = validade
    this.indicacao = indicacao
    this.restricoes = restricoes
    this.apresentacao = apresentacao
    this.volume = volume
  }
}

export function createProduto ({ rotulo, ...data }) {
  return Object.freeze(new Produto({ rotulo: createRotulo(rotulo), ...data }))
}
